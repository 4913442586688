import {FunctionComponent, useMemo, useState} from "react";
import {IPage} from "../../components/PageWrapper/types";
import {Link, DeviceVerificationPageContainer, Phrase, SubTitle, UpdatePhoneFieldContainer} from "./DeviceVerificationPage.style";
import SubmitButton from "../../components/SubmitButton/SubmitButton";
import I18n from "../../components/I18n/I18n";
import TextField from "../../components/Fields/TextField/TextField";
import {asI18nKey} from "../../common/utilities/Localization";
import useIdentityForm from "../../common/hooks/UseIdentityForm";
import {CountryCode, parsePhoneNumberWithError} from "libphonenumber-js";
import Dropdown from "../../components/Fields/Dropdown/Dropdown";
import countries from "../../common/utilities/Countries";
import { defaultLanguage } from '../../common/config/LanguageConfig';
import {ServerError} from "../../common/utilities/API/types";
import {AlertType} from "../../components/AlertToast/types";
import useAPI from "../../common/hooks/UseAPI";
import {updatePhone} from "./DeviceVerificationService";
import useIdentityNavigation from "../../common/hooks/UseIdentityNavigation";
import {IDropDownObjects} from "./types";
import useIdentityParams from "../../common/hooks/Parameters/UseIdentityParams";
import usePageAccessGuard from "../../common/hooks/UsePageAccessGuard";
import maskPhoneNumber from "../../common/utilities/MaskPhoneNumber";
import SupportModal from '../../components/SupportModal/SupportModal';
import LinkParagraph from "../../components/LinkParagraph/LinkParagraph";

const UpdatePhonePage: FunctionComponent<IPage> = (
    { trackingCategory, translate, onServerAlert, onUnexpectedError }
) => {
    const { navigate } = useIdentityNavigation();
    const api = useAPI();
    const { setInput, formInputs, inputsVerificationTrigger, submissionTrigger, logSubmissionFailure } = useIdentityForm();
    const [prefix, setPrefix] = useState('');
    const { serverParams, allParams } = useIdentityParams<{
        tokenvalid: boolean;
        email: string;
        token: string;
    }>();
    const [supportModalOpen, setSupportModalOpen] = useState<boolean>(false);

    usePageAccessGuard(!serverParams.tokenvalid);

    const { dropdownOptions, countryHash} = useMemo(() => countries.reduce((output, country) => {
        const test = {
            value: `+${country.area}`,
            text: country.ISO3316Code,
            image: country.flag,
        }
        output.dropdownOptions.push(test);
        output.countryHash[`+${country.area}`] = test;
        return output;

    }, { dropdownOptions: [], countryHash: {} } as IDropDownObjects), []);

    const phoneNumberValidator:RegExp = {
        ...new RegExp(''),
        ...{ test: (value: string) => {
                const {text} = countryHash[prefix] || {};
                if (!text)
                    return false;
                try {
                    return parsePhoneNumberWithError(value.toString(), text as CountryCode)?.isValid?.()
                } catch {
                    return false;
                }
            } }
    };

    const onSubmit = async ({ prefix, number }:  Record<string, string>) => {
        const phoneNumber = parsePhoneNumberWithError(number, countryHash[prefix].text as CountryCode).number;
        return await api(() => updatePhone(phoneNumber, allParams.email, allParams.token), {
            errorsMap: () => [
                {
                    error: ServerError.InvalidToken,
                    message: translate(asI18nKey('device_verification.error.invalid_token'))
                },
                {
                    error: ServerError.InvalidPhoneNumber,
                    message: translate(asI18nKey('device_verification.error.invalid_phone_number'))
                },
                {
                    error: ServerError.CodeVerificationRejected,
                    message: translate(asI18nKey('device_verification.error.update_phone_limit_lock'))
                },
                {
                    error: ServerError.CodeIssueFailure,
                    message: translate(asI18nKey('device_verification.error.code_issue_failure'))
                }
            ],
            onSuccess: async ({ status }) => {
                switch (status) {
                    case 200:
                        const maskedPhoneNumber = maskPhoneNumber(phoneNumber);
                        navigate('DeviceVerificationVerify', undefined, { ...allParams, maskedPhoneNumber });
                        break;
                }
            },
            onError: ({ message, forceRefresh, mappedHandler }) => {
                if (message === ServerError.IncorrectCredentials) {
                    logSubmissionFailure(message);
                    return navigate('Login');
                }
                if (!!message && mappedHandler) {
                    let alertType = mappedHandler.alertType || AlertType.Error;
                    onServerAlert(message, alertType);
                    logSubmissionFailure(message);
                }
                else onUnexpectedError(forceRefresh);
            }
        });
    }

    return <DeviceVerificationPageContainer>
        <SubTitle>
            <I18n>device_verification.update.sub_title</I18n>
        </SubTitle>

        <UpdatePhoneFieldContainer>
            <Dropdown
                name={'prefix'}
                automationName={'prefix'}
                label={translate(asI18nKey('device_verification.update.label_text'))}
                options={dropdownOptions}
                onChange={(...args) => {
                    const [_, value] = args;
                    setPrefix(value as string);
                    setInput.apply(this, args);
                }}
                size={{
                    height: 16,
                    width: 40,
                }}
                verticalMargin={{
                    top: 0,
                    bottom: 0
                }}
                initialValue={dropdownOptions.find(x => x.text === defaultLanguage.cookieCode.split('-')[1].toUpperCase())}
                focusOnPageLoad
                shouldVerify={inputsVerificationTrigger.active}
                trackingCategory={trackingCategory}
                onEnterPress={submissionTrigger.fire}
            />
            <TextField
                name={'number'}
                automationName={'number'}
                type={'Phone'}
                onChange={setInput}
                focusOnPageLoad
                required
                verticalMargin={{
                    top: 0,
                    bottom: 0
                }}
                verificationConfig={{
                    pattern: [ phoneNumberValidator ],
                    customError: translate(asI18nKey('device_verification.error.invalid_phone_number'))
                }}
                shouldVerify={inputsVerificationTrigger.active}
                trackingCategory={trackingCategory}
                onEnterPress={submissionTrigger.fire}
            />
        </UpdatePhoneFieldContainer>

        <SubmitButton
            automationName={'submit-button'}
            formInputs={formInputs}
            onSuccess={onSubmit}
            trackingCategory={trackingCategory}
            onFailedVerification={inputsVerificationTrigger.fire}
            shouldSubmit={submissionTrigger.active}
            trackingComponentName={'phone update'}
        >
            <I18n>device_verification.update.button_text</I18n>
        </SubmitButton>
        <LinkParagraph
            prefix={translate(asI18nKey('device_verification.general.help_text'))}
            linkLabel={translate(asI18nKey('device_verification.general.help_link_text'))}
            callback={() => setSupportModalOpen(true)}
        />
        <SupportModal
            isOpen={supportModalOpen}
            onClose={() => setSupportModalOpen(false)}
        />
    </DeviceVerificationPageContainer>
}

export default UpdatePhonePage;
